import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    const publicVapidKey = 'BAETgd3bU-r0W-UiipeR_Wn-00SLPXjKwwaSl54McjzhkQNXOppdY2c4Y5EcQ-5QYiZ64V0v-hqguS03lFgymL8';

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/sw.js').then(async (registration) => {
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });

        await fetch('https://flask.oker.cloud/subscribe', {
          method: 'POST',
          body: JSON.stringify(subscription),
          headers: {
            'Content-Type': 'application/json'
          }
        }).then((res)=>{
            console.log(res)
        });
      });
    }
  }, []);

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  return (
    <div className="App">
      <h1>Weather Alerts</h1>
    </div>
  );
}

export default App;
